import styled from "../Themes";

export const Content = styled<any>(`div`)`
  width: 90%;
  margin: 0 auto;
  padding: 40px;

  @media (max-width: 900px) {
    width: 100%;
    padding: 0;
  }
`;

export const TableButton = styled(`div`)`
  height: 35px;
  width: 35px;
  padding: 5px;
  cursor: pointer;
  float: right;
`;

export const TableContainer = styled(`div`)`
  overflow: auto;
`;

export const Table = styled(`table`)`
  border-collapse: collapse;
  text-indent: 0;
  line-height: 24px;
  border-width: 1px;
  border-style: solid;
  border-color: ${({ theme }) => theme.color.table_border[2]};
  table-layout: fixed;
  width: 100%;
  background-color: ${({ theme }) => theme.color.table_header[2]};
`;

export const TableHead = styled(`thead`)`
  inset-block-start: 0;
  border-width: 1px 1px 0 1px;
  border-style: solid;
  border-color: ${({ theme }) => theme.color.table_border[2]};
  background-color: ${({ theme }) => theme.color.table_header[2]};
`;

export const TableBody = styled(`tbody`)`
  position: relative;
  border-width: 1px 0 0 0;
  border-style: solid;
  border-color: ${({ theme }) => theme.color.table_border[2]};
  background-color: ${({ theme }) => theme.color.table_body[2]};
`;

export const TableRow = styled(`tr`)`
  border-width: 1px 0 0 0;
  border-style: solid;
  border-color: ${({ theme }) => theme.color.table_border[2]};
  background-color: ${({ theme }) => theme.color.table_body[2]};
`;

export const TableHeadRow = styled(`tr`)`
  border-width: 0 0 0 0;
  border-style: solid;
  border-color: ${({ theme }) => theme.color.table_border[2]};
`;

export const TableHeadCell = styled(`th`)`
  color: ${({ theme }) => theme.color.font[2]};
  font-weight: 600;
  text-align: left;
  padding: 12px 18px;
  line-height: 18px;
  overflow: visible;
  white-space: nowrap;
  text-overflow: ellipsis;
  position: relative;

  &:last-child {
    overflow: hidden;
  }
`;

export const TableFilterCell = styled(`th`)`
  color: ${({ theme }) => theme.color.font[2]};
  font-weight: 600;
  text-align: left;
  line-height: 18px;
  padding: 0 18px 12px;
`;

export const TableDataCell = styled<any>(`td`)`
  padding: 12px 18px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  transition: all 0.25s ease;
`;

export const PageButton = styled<any>(`button`)`
  border-style: solid;
  border-radius: 5px;
  border-color: ${({ theme, selected }) => (selected ? theme.color.primary[2] : "transparent")};
  border-width: 1px;
  color: ${({ theme }) => theme.color.primary[2]};
  cursor: ${({ selected }) => (selected ? "initial" : "pointer")};
  min-width: 28px;
  height: 28px;
  font-weight: 500;
  line-height: 12px;
  margin: 2px;
  font-size: 14px;
  padding: 0 2px;
  user-select: none;

  &:hover {
    border-color: ${({ theme }) => theme.color.primary[2]};
  }

  :disabled {
    color: ${({ theme }) => theme.color.font[2]};
    opacity: 0.5;
  }
`;

export const PageNumber = styled<any>(PageButton)`
  @media (max-width: 768px) {
    display: none;
  }
`;

export const FilterInput = styled<any>(`input`)`
  transition: all 250ms;
  width: 100%;
  text-align: left;
  border: 1px solid ${({ theme, error }) => (error ? theme.color.error[2] : theme.color_30.panel_fg[0])};
  background: ${({ theme }) => theme.color.input_bg[2]};
  color: ${({ theme }) => theme.color.font_bold[2]};
  padding: 7px 7px;
  border-radius: 3px;
  font-family: ${({ theme }) => theme.fontFamily.body};
  font-size: ${({ theme }) => theme.fontSize.m};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  min-height: 34px;
  outline: none;
  margin-right: 6px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  ::placeholder {
    opacity: 0.5;
  }

  &:hover {
    border: 1px solid ${({ theme, error }) => (error ? theme.color.error[2] : theme.color_80.panel_fg[0])};
  }

  :focus {
    box-shadow: 0 0 0 1px ${({ theme }) => theme.color.primary[2]};
  }

  :disabled {
    opacity: 0.5;
  }
`;

export const SortHeader = styled<any>(`div`)`
  cursor: ${({ canSort }) => (canSort ? "pointer" : "initial")};
  user-select: none;
  position: relative;
  padding-right: 18px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  &::before {
    border: 4px solid transparent;
    content: "";
    display: ${({ canSort }) => (canSort ? "block" : "none")};
    height: 0;
    right: 5px;
    top: 50%;
    position: absolute;
    width: 0;
    border-bottom-color: ${({ theme, sortDirection }) => (sortDirection === "asc" ? theme.color.primary[2] : theme.color.font[2])};
    margin-top: -9px;
  }

  &::after {
    border: 4px solid transparent;
    content: "";
    display: ${({ canSort }) => (canSort ? "block" : "none")};
    height: 0;
    right: 5px;
    top: 50%;
    position: absolute;
    width: 0;
    border-top-color: ${({ theme, sortDirection }) => (sortDirection === "desc" ? theme.color.primary[2] : theme.color.font[2])};
    margin-top: 1px;
  }
`;

export const SelectInput = styled<any>(`select`)`
  width: 100%;
  text-align: left;
  border: 1px solid ${({ theme, error }) => (error ? theme.color.error[2] : theme.color_30.panel_fg[0])};
  background: ${({ theme }) => theme.color.input_bg[2]};
  color: ${({ theme }) => theme.color.font_bold[2]};
  padding: 7px 7px;
  border-radius: 3px;
  font-family: ${({ theme }) => theme.fontFamily.body};
  font-size: ${({ theme }) => theme.fontSize.m};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  min-height: 34px;
  outline: none;

  ::placeholder {
    opacity: 0.5;
  }

  :disabled {
    opacity: 0.4;
  }
`;

export const PaginationContainer = styled(`div`)`
  background-color: ${({ theme }) => theme.color.table_header[2]};
  border-width: 0px 1px 1px 1px;
  border-style: solid;
  border-color: ${({ theme }) => theme.color.table_border[2]};
`;

export const Resizer = styled(`div`)`
  z-index: 1;
  position: absolute;
  right: -5px;
  top: 0;
  height: 100%;
  width: 11px;
  cursor: col-resize;
  user-select: none;
  touch-action: none;
  background-color: ${({ theme }) => theme.color.table_border[2]};
  padding: 5px;
  background-clip: content-box;
`;

export const PaginationFlex = styled(`div`)`
  text-align: right;
  padding: 12px;
  display: flex;
  justify-content: space-between;
`;

export const RowCount = styled(`div`)`
  @media (max-width: 576px) {
    display: none;
  }
`;

export const TableHeaderButtons = styled(`div`)`
  padding-bottom: 12px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const Dot = styled(`span`)`
  z-index: 1;
  height: 12px;
  width: 12px;
  background-color: ${({ theme }) => theme.color.danger[2]};
  border-radius: 50%;
  position: absolute;
  top: 2px;
  right: 2px;
`;

export const DesktopDiv = styled(`div`)`
  display: block;

  @media (max-width: 576px) {
    display: none;
  }
`;

export const MobileDiv = styled(`div`)`
  display: none;

  @media (max-width: 576px) {
    display: block;
  }
`;
